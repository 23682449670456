<template>
  <div>
    <Notification ref="notification" />
    <!-- <Header /> -->
    <Header v-if="showAdminHeader" />
    <ContractorHeader v-if="showContractorHeader" />
    <CompanyHeader v-if="showCompanyHeader" />
    <Breadcrumbs v-if="showBreadcrumbs" :childBreadcrumb="childBreadcrumb"/>
    <div class="bg-gray-100 h-full min-h-screen">
      <div class="container mx-auto">
        <router-view @setChildBreadcrumb="setChildBreadcrumb" />
      </div>
    </div>
  </div>
</template>

<script>
import Header from '@/components/Header'
import Breadcrumbs from '@/components/Breadcrumbs'
import Notification from '@/components/Notification'

// Marketplace - Contractor
import ContractorHeader from '@/components/marketplace/contractor/Header'
// Marketplace - Company
import CompanyHeader from '@/components/marketplace/company/Header'

export default ({
  // metaInfo: {
  //   // if no subcomponents specify a metaInfo.title, this title will be used
  //   title: 'Default Title',
  //   // all titles will be injected into this template
  //   titleTemplate: '%s | My Awesome Webapp'
  // },
  components: {
    Header,
    ContractorHeader,
    CompanyHeader,
    Breadcrumbs,
    Notification
  },
  data() {
    return {
      childBreadcrumb: ''
    }
  },
  computed: {
    showAdminHeader() {
      return this.$route.meta.adminHeader;
    },
    showContractorHeader() {
      return this.$route.meta.contractorHeader;
    },
    showCompanyHeader() {
      return this.$route.meta.companyHeader;
    },
    showBreadcrumbs() {
      if (this.$route.meta.breadcrumbs === undefined) {
        return true;
      } else {
        return this.$route.meta.breadcrumbs;
      }
    }
  },
  methods: {
    setChildBreadcrumb(breadcrumb) {
      this.childBreadcrumb = breadcrumb;
    },
    notify(message, type) {
      this.$refs.notification.showNotification(message, type);
    }
  },
  created () {
    document.title = 'Screened Resume Parser'
  }
})
</script>
<style>
/* .hiddenModal {
    visibility: hidden;
}*/

.disabledSave {
    cursor: not-allowed !important;
    pointer-events: all !important;
    background-color: gray !important;
}

.visibleModal {
    visibility: visible !important;
}
/* HIDE ARROWS FROM NUMBER INPUT */
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}
</style>