<template>
    <TransitionRoot as="template" :show="popupOpen">
      <Dialog as="div" class="relative z-10" @close="popupOpen = false">
        <!-- This will make background gray out of the popup -->
        <TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0" enter-to="opacity-100" leave="ease-in duration-200" leave-from="opacity-100" leave-to="opacity-0">
          <div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </TransitionChild>
  
        <div class="fixed inset-0 z-10 overflow-y-auto">
            <div class="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                <TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95" enter-to="opacity-100 translate-y-0 sm:scale-100" leave="ease-in duration-200" leave-from="opacity-100 translate-y-0 sm:scale-100" leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
                    
                <DialogPanel class="relative transform overflow-visible rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
                    <div class="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4">
                        <DialogTitle as="h3" class="text-base font-semibold leading-6 text-gray-900 flex justify-between">
                                <span class="w-2/3">Export talent bench</span>
                        </DialogTitle>

                        <div class="mt-2">
                            <form class="w-full max-w-lg">
                                <div class="flex flex-wrap -mx-3 mb-2">
                                    <div class="w-full px-3">
                                        <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" for="bench-name">
                                            Name
                                        </label>
                                        <input class="appearance-none block w-full text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="bench-name" type="text" v-model="this.talentBenchName">
                                    </div>
                                </div>
                                <div class="flex flex-wrap -mx-3 mb-2">
                                    <div class="w-full px-3 flex flex-col">
                                        <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" for="bench-description">
                                            Description
                                        </label>
                                        <textarea class="rounded" placeholder="" name="bench-description" id="bench-description" cols="30" rows="3" v-model="this.talentBenchDescription"></textarea>
                                    </div>
                                </div>
                            </form>
                        </div>


                        <div class="flex flex-col mt-3">
                            <div class="flex justify-between mb-2">
                                <div class="flex flex-col">
                                    <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" for="bench-description">
                                    Profiles
                                    </label>
                                    <span class="text-xs text-gray-500">Select the profiles you want to export</span>
                                </div>
                                <div>
                                    <button type="button" class="inline-flex w-full justify-center rounded-md bg-olive px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-red-500 sm:ml-3 sm:w-auto" @click="changeTalentBenchSelect(1)">Select all</button>
                                    <button type="button" class="ml-2 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto" @click="changeTalentBenchSelect(0)">Select none</button>
                                </div>
                            
                            </div>

                            <div class="border rounded p-3 h-96 overflow-auto">
                                <div class="flex flex-col">
                                    <template v-for="profile in talentPoolMembers" :profile="profile" :key="profile.profile_hash" >
                                        <div class="border rounded p-3 mt-2 cursor-pointer select-none" :class="{ 'border-olive': this.talentBenchExport.includes(profile.profile_hash) }" @click="updatBenchExport(profile.profile_hash)">
                                            <span class="font-bold"> {{ profile.full_name }} </span>
                                            <span>&nbsp;( {{ profile.current_role }} in {{ profile.location }})</span>
                                        </div>
                                    </template>
                                </div>
                                
                            </div>
                        </div>

                    <!-- </div> -->
                    </div>
                    <div class="flex bg-gray-50 py-3 justify-between">
                        <div class="ml-5">
                            <span class="text-sm text-red-500">{{ ErrorMessage }}</span>
                        </div>
                        <div class="sm:flex sm:flex-row-reverse sm:px-6">
                            <button type="button" class="inline-flex w-full justify-center rounded-md bg-olive px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-red-500 sm:ml-3 sm:w-auto" @click="exportBench">{{ this.saveButtonText }}</button>
                            <button type="button" class="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto" @click="popupOpen = false" ref="cancelButtonRef">Cancel</button>
                        </div>

                    </div>
                </DialogPanel>
                </TransitionChild>
            </div>
        </div>
      </Dialog>
    </TransitionRoot>
  </template>


<script>

import { Dialog, DialogPanel, DialogTitle, TransitionChild, TransitionRoot } from '@headlessui/vue'

let apiEndpoint = process.env.VUE_APP_API_BASEURL

export default {
    components: {
        Dialog,
        DialogPanel,
        DialogTitle,
        TransitionChild,
        TransitionRoot,
    },
    data() {
        return {
            popupOpen: false,
            profile: {},
            talentBenchName: '',
            talentBenchDescription: '',
            saveButtonText: 'Export',
            talentPoolHash: null,
            talentPoolMembers: [],
            talentBenchExport: [],
            ErrorMessage: '',
        }
    },
    methods: {
        openModal(talentPoolHash, members) {
            this.popupOpen = true
            this.talentPoolHash = talentPoolHash
            this.talentPoolMembers = members
            this.talentBenchName = ''
            this.talentBenchDescription = ''
            this.ErrorMessage = ''

            // Fill talentBenchExport with all the profile ids (profile_hash) from members
            this.talentBenchExport = []
            for (let i = 0; i < members.length; i++) {
                this.talentBenchExport.push(members[i].profile_hash)
            }
        },
        async exportBench() {
            if (this.talentBenchName == '' || this.talentBenchDescription == '') {
                this.ErrorMessage = 'All the fields are required.'
                return
            } else {
                if (this.talentBenchExport.length == 0) {
                    this.ErrorMessage = 'You need to select at least one profile.'
                    return
                }
            }
            const postObject = {
                talent_pool_hash: this.talentPoolHash,
                name: this.talentBenchName,
                description: this.talentBenchDescription,
                profiles: this.talentBenchExport,
            }

            const name = this.talentBenchName

            try {
                await fetch(apiEndpoint + "/talentpool.php?exportBench", {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' +  localStorage.getItem('screenedToken'),
                    },
                    body: JSON.stringify(postObject),
                }).then(function(response) {                    
                    const outputFileName = name + '.pdf'
                    response.blob().then(function(myBlob) {
                        const url = window.URL.createObjectURL(myBlob);
                        const link = document.createElement('a');
                        link.href = url;
                        link.setAttribute('download', outputFileName);
                        document.body.appendChild(link);
                        link.click();
                    })

                    this.ErrorMessage = '';
                });
            } catch (error) {
                console.log(error);
                this.saveButtonText = 'Export'
            }

        },
        updatBenchExport(profileHash) {
            if (this.talentBenchExport.includes(profileHash)) {
                this.talentBenchExport = this.talentBenchExport.filter(item => item !== profileHash)
            } else {
                this.talentBenchExport.push(profileHash)
            }
        },
        changeTalentBenchSelect(action) {
            if (action == 1) {
                // Select all
                this.talentBenchExport = []
                for (let i = 0; i < this.talentPoolMembers.length; i++) {
                    this.talentBenchExport.push(this.talentPoolMembers[i].profile_hash)
                }
            } else {
                // Select none
                this.talentBenchExport = []
            }
        },
    }
}
</script>