<template class="">
    <div class="pt-7 flex justify-center">
      <div class="bg-white shadow-md rounded-xl px-8 pt-6 pb-8 mb-4 flex flex-col w-1/2">
        <div class="mb-4">
          <h3 class="font-bold">Sign Up</h3>
        </div>

        <div v-if="this.error" class="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative mb-4" role="alert">
          <span class="block sm:inline">{{ this.errorMessage }}</span>
        </div>
        
        <div class="flex flex-wrap -mx-3 mb-6">
          <div class="w-full px-3">
            <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" for="grid-companyname">
              Company Name
            </label>
            <input class="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="grid-companyname" type="text" v-model="this.company_name">
          </div>
        </div>

        <div class="flex flex-wrap -mx-3 mb-6">
          <div class="w-full px-3">
            <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" for="grid-companyoffice">
              Company Office
            </label>
            <input class="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="grid-companyoffice" type="text" v-model="this.company_office">
          </div>
        </div>

        <div class="flex flex-wrap -mx-3 mb-6">
          <div class="w-full px-3">
            <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" for="grid-companywebsite">
              Company Website
            </label>
            <input class="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="grid-companywebsite" type="text" v-model="this.company_website">
          </div>
        </div>

        <div class="flex flex-wrap -mx-3 mb-6">
          <div class="w-full px-3">
            <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" for="grid-contactname">
              Contact Name
            </label>
            <input class="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="grid-contactname" type="text" v-model="this.contact_name">
          </div>
        </div>


        <div class="flex flex-wrap -mx-3 mb-6">
          <div class="w-full px-3">
            <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" for="grid-contactposition">
              Position
            </label>
            <input class="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="grid-fname" type="text" v-model="this.contact_position">
          </div>
        </div>

        <div class="flex flex-wrap -mx-3 mb-6">
          <div class="w-full md:w-1/2 px-3">
            <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" for="grid-contactemail">
              Email
            </label>
            <input class="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="grid-contactemail" type="text" v-model="this.contact_email">
          </div>
          <div class="w-full md:w-1/2 px-3">
            <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" for="grid-contactphone">
              Phone
            </label>
            <input class="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="grid-contactphone" type="text" v-model="this.contact_phone">
          </div>
        </div>

        <div class="flex flex-wrap -mx-3 mb-6">
          <div class="w-full md:w-1/2 px-3">
            <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" for="grid-password">
              Password
            </label>
            <input class="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="grid-password" type="password" v-model="this.password">
          </div>
          <div class="w-full md:w-1/2 px-3">
            <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" for="grid-passwordconfirm">
              Confirm password
            </label>
            <input class="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="grid-passwordconfirm" type="password" v-model="this.passwordConfirm">
          </div>
        </div>

        <div class="flex items-end justify-between">
          <router-link to="/marketplace/company/login">
            <button class="bg-olive hover:bg-blue-dark text-white font-bold py-2 px-4 rounded" type="button">
              Return to login
            </button>
          </router-link>

          <button class="bg-olive hover:bg-blue-dark text-white font-bold py-2 px-4 rounded" type="button" @click="submitSignup">
            Submit
          </button>
        </div>
    </div>
  </div>
</template>

<script>
let apiEndpoint = process.env.VUE_APP_API_BASEURL
import Datepicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css'

export default {
  name: 'Signup',
  components: {
    Datepicker
  },
  data() {
    return {
      date: new Date(),
      company_name: "",
      company_office: "",
      company_website: "",
      contact_name: "",
      contact_position: "",
      contact_email: "",
      contact_phone: "",
      password: "",
      passwordConfirm: "",
      mandatoryFields: ['company_name', 'company_office', 'company_website', 'contact_name', 'contact_position', 'contact_email', 'contact_phone', 'password', 'passwordConfirm']
    };
  },
  methods: {
    async submitSignup() {
      // Scroll to top in case of error message
      window.scrollTo(0, 0)
      this.error = false
      
      // Loop over mandatory fields and check if they are empty
      this.mandatoryFields.forEach(field => {
        if (this[field] === "") {
          this.error = true
          this.errorMessage = 'Please fill all the fields'
        }
      })

      // Check if passwords match
      if (this.password !== this.passwordConfirm) {
        this.error = true
        this.errorMessage = 'Passwords do not match'
      }

      if (!this.error) {
        let postObject = {
            company_name: this.company_name,
            company_office: this.company_office,
            company_website: this.company_website,
            contact_name: this.contact_name,
            contact_position: this.contact_position,
            contact_email: this.contact_email,
            contact_phone: this.contact_phone,
            password: this.password,
        }

        try {
          await fetch(apiEndpoint + "/marketplace.php?companySignup", {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify(postObject),
          })
          .then(response => response.json())
          .then(data => {
            if (data.success === false) {
              this.error = true
              this.errorMessage = data.message
            } else {
              // Write token to localStorage
              localStorage.setItem('companyToken', data.token);

              // Redirect to profiles
              this.$router.push('profiles') 
            }
          })
        } catch (error) {
            console.log(error)
          console.log('something went realllly worng')
          // this.saveButtonText = 'Save changes'
        }
      }
    },
    logoutUser() {  
        // Set state
        this.$store.commit('setLoggedIn', false)
        // Remove localstorage
        localStorage.removeItem('companyToken');

        // Redirect to login
        this.$router.push('Login') 
    },
  },
  created() {
    // this.logoutUser();
  },
}
</script>
