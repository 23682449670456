import { createStore, storeKey } from 'vuex'

// Create a new store instance.
export default createStore({
    state () {
      return {
        count: 0,
        isLoggedIn: false,
      }
    },
    mutations: {
      increment (state) {
        state.count++
      },
      setLoggedIn(state, value) {
        state.isLoggedIn = value
      }
    }
  });