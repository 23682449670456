<template>
    <TransitionRoot as="template" :show="popupOpen">
      <Dialog as="div" class="relative z-10" @close="popupOpen = false">
        <!-- This will make background gray out of the popup -->
        <TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0" enter-to="opacity-100" leave="ease-in duration-200" leave-from="opacity-100" leave-to="opacity-0">
          <div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </TransitionChild>
  
        <div class="fixed inset-0 z-10 overflow-y-auto">
            <div class="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                <TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95" enter-to="opacity-100 translate-y-0 sm:scale-100" leave="ease-in duration-200" leave-from="opacity-100 translate-y-0 sm:scale-100" leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
                <DialogPanel class="relative transform overflow-visible rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
                    <div class="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4">
                        <TabGroup :selectedIndex="this.selectedTab" @change="changeTab">
                            <DialogTitle as="h3" class="text-base font-semibold leading-6 text-gray-900 flex justify-between">
                                <!-- <span class="w-2/3">{{ this.profile.full_name }} <span class="text-xs">({{ profile.uuid }})</span></span> -->
                                <TabList class="flex w-full justify-end space-x-3">
                                    <Tab :class="{ 'bg-olive rounded px-2 py-1 text-white': this.selectedTab == 0, 'bg-white text-black': this.selectedTab != 0 }">Profile</Tab>
                                    <Tab :class="{ 'bg-olive rounded px-2 py-1 text-white': this.selectedTab == 1, 'bg-white text-black': this.selectedTab != 1 }">Notes</Tab>
                                    <Tab :class="{ 'bg-olive rounded px-2 py-1 text-white': this.selectedTab == 2, 'bg-white text-black': this.selectedTab != 1 }">Talent Pools</Tab>
                                </TabList>
                            </DialogTitle>

                            <TabPanels>
                                <TabPanel class="">
                                    <div class="mt-2">
                                        <form class="w-full max-w-lg">
                                        <div class="flex flex-wrap -mx-3 mb-2">
                                            <div class="w-full px-3">
                                            <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" for="grid-name">
                                                Name
                                            </label>
                                            <input class="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="grid-name" type="text" v-model="this.editableProfileData.full_name">
                                            </div>
                                        </div>
                                        <div class="flex flex-wrap -mx-3 mb-2">
                                            <div class="w-full px-3">
                                            <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" for="grid-email">
                                                Email
                                            </label>
                                            <input class="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="grid-email" type="text" v-model="this.editableProfileData.email">
                                            </div>
                                        </div>
                                        <div class="flex flex-wrap -mx-3 mb-2">
                                            <div class="w-full px-3">
                                            <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" for="grid-phone">
                                                Phone
                                            </label>
                                            <input class="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="grid-phone" type="text" v-model="this.editableProfileData.phone">
                                            </div>
                                        </div>
                                        <div class="flex flex-wrap -mx-3 mb-2">
                                            <div class="w-full px-3">
                                            <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" for="grid-location">
                                                Location
                                            </label>
                                            <input class="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="grid-location" type="text" v-model="this.editableProfileData.location">
                                            </div>
                                        </div>
                                        <div class="flex flex-wrap -mx-3 mb-2">
                                            <div class="w-full px-3">
                                            <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" for="grid-linkedin">
                                                Linkedin URL
                                            </label>
                                            <input class="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="grid-linkedin" type="text" v-model="this.editableProfileData.linkedin_url">
                                            </div>
                                        </div>

                                        <div class="flex flex-wrap -mx-3 mb-6">
                                            <div class="w-full md:w-1/2 px-3 mb-6 md:mb-0">
                                            <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" for="grid-employment-type">
                                                Employment type
                                            </label>
                                            <div class="relative">
                                                <select class="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="grid-employment-type" v-model="this.editableProfileData.employeeType">
                                                    <option value="0" :selected="this.editableProfileData.employeeType == 0">Permanent</option>
                                                    <option value="1" :selected="this.editableProfileData.employeeType == 1">Contract</option>
                                                    <option value="2" :selected="this.editableProfileData.employeeType == 2">Both</option>
                                                </select>
                                                <div class="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                                                <svg class="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"/></svg>
                                                </div>
                                            </div>
                                            <!-- <p class="text-red-500 text-xs italic">Please fill out this field.</p> -->
                                            </div>
                                            <div class="w-full md:w-1/2 px-3">
                                                <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" for="grid-availability">
                                                    Availability
                                                </label>
                                                <div class="relative">
                                                    <select @change="availabilityChange($event)" class="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500" v-model="this.selectedAvailability" id="grid-availability">
                                                        <option v-for="availability in availabilityOptions" :value="availability.value">{{ availability.label }}</option>
                                                    </select>

                                                    <div class="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                                                    <svg class="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"/></svg>
                                                    </div>
                                                </div>

                                                <Popover v-if="calendarOpen" class="relative">
                                                    <PopoverPanel class="absolute z-10">
                                                        <Datepicker class="my-3" v-model="date" inline autoApply @update:modelValue="updateCalendarDate" hideOffsetDates :enableTimePicker="false" ref="datepicker"></Datepicker>
                                                    </PopoverPanel>
                                                </Popover>
                                            </div>                                            
                                        </div>

                                        <div class="flex flex-wrap -mx-3 mb-6">
                                            <div class="w-full md:w-1/2 px-3">
                                            <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" for="grid-salary">
                                                Salary
                                            </label>
                                            <input class="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="grid-salary" type="text" v-model="this.editableProfileData.salary">
                                            </div>
                                            <div class="w-full md:w-1/2 px-3">
                                            <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" for="grid-rate">
                                                Rate
                                            </label>
                                            <input class="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="grid-rate" type="text" v-model="this.editableProfileData.rate">
                                            </div>
                                        </div>

                                        <div class="flex flex-wrap -mx-3 mb-2">
                                            <div class="w-full px-3">
                                            <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" for="grid-visa">
                                                Visa status
                                            </label>
                                            <input class="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="grid-visa" type="text" placeholder="Type visa status here..." v-model="this.editableProfileData.visa">
                                            </div>
                                        </div>
                                        <div class="flex flex-wrap -mx-3 mb-2">
                                            <div class="w-full px-3">
                                                <label class="uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" for="grid-otw">
                                                    Open to work
                                                </label>
                                                <input class="ml-3 appearance-none bg-gray-200 text-gray-700 border border-gray-200 rounded " id="grid-otw" type="checkbox" v-model="this.editableProfileData.trafficlight_status" true-value="1" false-value="0" @click="testfunc()">
                                            </div>
                                        </div>
                                        </form>
                                    </div>
                                </TabPanel>

                                <TabPanel class="">
                                    <div class="flex flex-col mt-2">
                                        
                                        <div v-if="!this.profile.notes.length" class="border my-3 rounded p-2 h-96 flex items-center justify-center ">
                                            <span  class="text-center text-gray-200">This profile does not have any notes yet</span>
                                        </div>
                                        <div v-if="this.profile.notes.length" class="border my-3 rounded p-2 h-96 overflow-auto">
                                            <div v-for="(note, index) in this.profile.notes" :key="note.id" :index="index" class="flex flex-col bg-gray-100 p-2 border rounded-md mb-2">
                                                <div class="text-xs">{{ note.timestamp }}</div>
                                                <div class="text-sm">{{ note.note }}</div>
                                            </div>
                                        </div>

                                        <div class="mt-5 flex flex-col">
                                            <textarea class="rounded" placeholder="Type your new note here..." name="" id="" cols="30" rows="3" v-model="this.newNote"></textarea>
                                            <button type="button" clcass="inline-flex justify-center rounded-md bg-olive px-3 py-2 text-sm font-semibold text-white shadow-sm my-3 items-center" @click="addNote">Add note</button>
                                        </div>

                                    </div>
                                </TabPanel>

                                <TabPanel class="">
                                    <div class="flex flex-col mt-2">
                                        <div class="border my-3 rounded p-2 h-96 overflow-auto">
                                            <div v-for="(pool, index) in this.allPools" :key="pool.talent_pool_hash" :index="index" :class="{ 'border-olive': this.profile.pools.includes(pool.talent_pool_hash) }" class="flex flex-col bg-gray-100 p-2 border rounded-md mb-2 cursor-pointer" @click="updateProfilePools(pool.talent_pool_hash)">
                                                <div class="text-sm">{{ pool.name }}</div>
                                                <div class="text-xs">{{ pool.description }}</div>
                                            </div>
                                        </div>
                                    </div>
                                </TabPanel>
                            </TabPanels>
                        </TabGroup>
                    <!-- </div> -->
                    </div>
                    <div class="bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
                    <button type="button" class="inline-flex w-full justify-center rounded-md bg-olive px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-red-500 sm:ml-3 sm:w-auto" @click="saveChanges">{{ this.saveButtonText }}</button>
                    <button type="button" class="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto" @click="popupOpen = false" ref="cancelButtonRef">Cancel</button>
                    </div>
                </DialogPanel>
                </TransitionChild>
            </div>
        </div>
      </Dialog>
    </TransitionRoot>
  </template>
<script>
import { ref } from 'vue'

import { Dialog, DialogPanel, DialogTitle, TransitionChild, TransitionRoot } from '@headlessui/vue'
import { TabGroup, TabList, Tab, TabPanels, TabPanel } from '@headlessui/vue'
import Datepicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css'

let apiEndpoint = process.env.VUE_APP_API_BASEURL

export default {
    components: {
        Dialog,
        DialogPanel,
        DialogTitle,
        TransitionChild,
        TransitionRoot,
        TabGroup,
        TabList,
        Tab,
        TabPanels,
        TabPanel,
        Datepicker,
    },
    data() {
        return {
            date: new Date(),
            popupOpen: false,
            profile: {},
            newNote: "",
            selectedTab: 0,
            allPools: [],
            editableProfileData: {
                full_name: null,
                email: null,
                phone: null,
                location: null,
                linkedin_url: null,
                employeeType: null,
                availability: null,
                salary: null,
                rate: null,
                visa: null,
                trafficlight_status: 0
            },
            calendarOpen: false,
            availabilityOptions: [
                {
                    "value": 0,
                    "label": "Select availability"
                },
                {
                    "value": 0.2,
                    "label": "1 week"
                },
                {
                    "value": 0.5,
                    "label": "2 weeks"
                },
                {
                    "value": 1,
                    "label": "1 month"
                },
                {
                    "value": 999,
                    "label": "Custom date"
                }

            ],
            selectedAvailability: 0,
            saveButtonText: "Save changes",
        }
    },
    watch: {
        editableProfileData(newValue) {
            console.log(newValue); // Debugging: check the value of isChecked when it changes
        }
    },
    methods: {
        openModal(profile) {
            this.profile = profile
            this.popupOpen = true
            this.setEditableData()

            this.setAvailability(this.profile.availability)

            // If the availability is higher than 1 we need to add the custom date to the array
            if (this.availabilityOptions.length >= 6) {
                this.availabilityOptions.pop();
            }

            if (this.profile.pools == null) {
                this.getProfilePools()
            }

            if (this.selectedAvailability > 1) {
                let customDate = new Date(this.profile.availability * 1000)
                this.availabilityOptions.push({
                    "value": this.selectedAvailability,
                    "label": 'From: ' + customDate.toLocaleDateString()
                })
            }

            this.getProfileNotes()
            this.getAllPools()
        },
        async getProfilePools() {
            try {
                await fetch(apiEndpoint + '/talentpool.php?getProfilePools&ph=' + this.profile.profile_hash, {
                    headers: {
                        'Authorization': 'Bearer ' +  localStorage.getItem('screenedToken'),
                    }
                })
                .then(response => response.json())
                .then(data => {
                    this.profile.pools = []
                    for (let i = 0; i < data.length; i++) {
                        this.profile.pools.push(data[i].talent_pool_hash)
                    }
                });
            } catch (error) {
                console.log(error);
            }
        },
        setAvailability(v) {
            if (v == null) {
                this.selectedAvailability = 0
            } else {
                this.selectedAvailability = v
            }
        },
        setEditableData() {
            this.editableProfileData.full_name = this.profile.full_name
            this.editableProfileData.email = this.profile.email
            this.editableProfileData.phone = this.profile.phone
            this.editableProfileData.location = this.profile.location
            this.editableProfileData.linkedin_url = this.profile.linkedin_url
            this.editableProfileData.employeeType = this.profile.employeeType
            this.editableProfileData.availability = this.profile.availability
            this.editableProfileData.salary = this.profile.salary
            this.editableProfileData.rate = this.profile.rate
            this.editableProfileData.visa = this.profile.visa
            this.editableProfileData.trafficlight_status = this.profile.trafficlight_status
        },
        availabilityChange(event) {
            if (event.target.value == 999) {
                this.calendarOpen = true
            } else {
                this.calendarOpen = false
            }
        },
        async getProfileNotes() {
            try {
                let response = await fetch(apiEndpoint + '/profile.php?getProfileNotes&ph=' + this.profile.profile_hash, {
                    headers: {
                        'Authorization': 'Bearer ' + localStorage.getItem('screenedToken'),
                    }
                });
                this.profile.notes = await response.json();
            } catch (error) {
                console.log(error);
            }
        },
        updateCalendarDate(newDate) {
            let newSelectedDate = (newDate.getTime() / 1000)
            this.calendarOpen = false

            // If we already have 5 dates, remove the latest one
            if (this.availabilityOptions.length >= 6) {
                this.availabilityOptions.pop();
            }

            // Add the new date to the dropdown
            this.availabilityOptions.push({
                "value": newSelectedDate,
                "label": "From " + newDate.toLocaleDateString()
            })

            this.selectedAvailability = newSelectedDate
        },
        async saveChanges() {
            if (this.editableProfileData.full_name.length <= 0) {
                this.$root.notify("The name of a candidate cannot be empty", "error");
                return
            }

            // If data did not change, do not send request
            if (this.editableProfileData.full_name == this.profile.full_name &&
                this.editableProfileData.email == this.profile.email &&
                this.editableProfileData.phone == this.profile.phone &&
                this.editableProfileData.location == this.profile.location &&
                this.editableProfileData.linkedin_url == this.profile.linkedin_url &&
                this.editableProfileData.employeeType == this.profile.employeeType &&
                this.editableProfileData.availability == this.profile.availability &&
                this.editableProfileData.salary == this.profile.salary &&
                this.editableProfileData.rate == this.profile.rate &&
                this.editableProfileData.visa == this.profile.visa &&
                this.editableProfileData.trafficlight_status == this.profile.trafficlight_status) {
                this.$root.notify('Changes saved successfully', "success");
                // this.$root.notify("It looks like no changes were made. Please make any modifications you need before saving", "warning");
                return
            }

            this.saveButtonText = 'Saving...'

            let postObject = {
                full_name: this.editableProfileData.full_name,
                email: this.editableProfileData.email,
                phone: this.editableProfileData.phone,
                location: this.editableProfileData.location,
                linkedin_url: this.editableProfileData.linkedin_url,
                employeeType: this.editableProfileData.employeeType,
                availability: this.selectedAvailability,
                salary: this.editableProfileData.salary,
                rate: this.editableProfileData.rate,
                visa: this.editableProfileData.visa,
                trafficlight_status: this.editableProfileData.trafficlight_status
            }

            try {
                await fetch(apiEndpoint + "/profile.php?updateProfile&ph=" + this.profile.profile_hash, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' +  localStorage.getItem('screenedToken'),
                    },
                    body: JSON.stringify(postObject),
                })
                .then(response => response.json())
                .then(data => {
                    this.saveButtonText = 'Save changes'
                    if (data.status == 'SUCCESS') {
                        this.$root.notify('Changes saved successfully', "success");

                        this.profile.full_name = data.data.full_name
                        this.profile.email = data.data.email
                        this.profile.phone = data.data.phone
                        this.profile.location = data.data.location
                        this.profile.linkedin_url = data.data.linkedin_url
                        this.profile.employeeType = data.data.employeeType
                        this.profile.availability = data.data.availability
                        this.profile.salary = data.data.salary
                        this.profile.rate = data.data.rate
                        this.profile.visa = data.data.visa
                        this.profile.trafficlight_status = data.data.trafficlight_status

                        this.setAvailability(data.data.availability)

                        this.setEditableData();
                    } else {
                        this.$root.notify(data.message, "error");
                    }
                })
            } catch (error) {
                this.saveButtonText = 'Save changes'
            }
        },
        async addNote() {
            if (this.newNote != "") {
                let postData = {
                    ph: this.profile.profile_hash,
                    note: this.newNote
                }

                try {
                    fetch(apiEndpoint + "/profile.php?addProfileNote", {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' +  localStorage.getItem('screenedToken'),
                    },
                    body: JSON.stringify(postData),
                    })
                    .then(data => {
                        this.getProfileNotes()
                    })
                } catch (error) {
                    // Show error
                }
                this.newNote = ""
            }
        },
        testfunc() {
            // console.log(this.editableProfileData)
        },
        changeTab(i) {
            this.selectedTab = i
        },
        async getAllPools() {
            try {
                let response = await fetch(apiEndpoint + "/talentpool.php?getAll", {
                    headers: {
                        'Authorization': 'Bearer ' +  localStorage.getItem('screenedToken'),
                    },
                })
                this.allPools = await response.json();
            } catch (error) {
                this.allPools = [];
            }
        },
        updateProfilePools(poolHash) {
            let index = this.profile.pools.indexOf(poolHash)
            if (index > -1) {
                this.profile.pools.splice(index, 1)
                this.removeProfileFromPool(poolHash)
                // Remove from profile data
                for (let i = 0; i < this.profile.talentPools.length; i++) {
                    if (this.profile.talentPools[i].talent_pool_hash == poolHash) {
                        this.profile.talentPools.splice(i, 1)
                    }
                }
            } else {
                this.profile.pools.push(poolHash)
                this.addProfileToPool(poolHash)
                
                // Add to profile data
                for (let i = 0; i < this.allPools.length; i++) {
                    if (this.allPools[i].talent_pool_hash == poolHash) {
                        this.profile.talentPools.push(this.allPools[i])
                    }
                }
            }
        },
        addProfileToPool(poolHash) {
            let postData = {
                tph: poolHash,
                ph: this.profile.profile_hash
            }

            try {
                fetch(apiEndpoint + "/talentpool.php?addProfileToPool", {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' +  localStorage.getItem('screenedToken'),
                    },
                    body: JSON.stringify(postData),
                    })
                .then(response => response.json())
                .then(data => {
                    //
                })
            } catch (error) {
                // Show error
            }
        },
        removeProfileFromPool(poolHash) {
            // Remove from database
            let postData = {
                tph: poolHash,
                ph: this.profile.profile_hash
            }

            try {
                fetch(apiEndpoint + "/talentpool.php?removeProfileFromPool", {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' +  localStorage.getItem('screenedToken'),
                },
                body: JSON.stringify(postData),
                })
                .then(response => response.json())
                .then(data => {
                //
                })
            } catch (error) {
                // Show error
            }
        },
    },
    
}
</script>