<template>
    <div class="pt-7">
      <div class="bg-white rounded-xl mb-7"></div>

      <div class="rounded-xl">
      <!-- This example requires Tailwind CSS v2.0+ -->
        <div class="flex flex-col items-center">
          <div class="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
            <div class="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
              <div class="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
                <table class="min-w-full divide-y divide-gray-200">
                  <thead class="bg-gray-50">
                    <tr>
                      <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                        #
                      </th>
                      <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                        File name
                      </th>
                      <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                        Profile
                      </th>
                      <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                        Upload date
                      </th>
                      <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                        Status
                      </th>
                    </tr>
                  </thead>
                  <tbody class="bg-white divide-y divide-gray-200">
                    <tr v-for="(upload, index) in uploads" :upload="upload" :key="upload.file_hash">
                      <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                        {{ index + 1}}
                      </td>
                      <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                        {{ upload.file_name }}
                      </td>
                      <td class="px-6 py-4 whitespace-nowrap text-sm text-olive">
                        <a href="">N/ A</a>
                      </td>
                      <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                        {{ upload.upload_date }}
                      </td>
                      <td class="px-6 py-4 whitespace-nowrap">
                        <span v-if="upload.status == 0" class="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-yellow-100 text-yellow-800">
                          Pending
                        </span>
                        <span v-else-if="upload.status == 1" class="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-green-100 text-green-800">
                          Success
                        </span>
                        <span v-else-if="upload.status == 2" class="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-red-100 text-red-800">
                          Error
                        </span>
                        <span v-else-if="upload.status == 3" class="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-yellow-100 text-yellow-800">
                          Pending review
                        </span>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
  </div>
</template>

<script>
let apiEndpoint = process.env.VUE_APP_API_BASEURL

export default {
  name: 'Uploads',
  components: {
  },
  data() {
    return {
      uploads: [],
    };
  },
  methods: {
    async getAllUploads() {
      try {
        // let response = await fetch(apiEndpoint + "/upload.php?getAll");
        let response = await fetch(apiEndpoint + "/upload.php?getAll", {
          headers: {
            'Authorization': 'Bearer ' +  localStorage.getItem('screenedToken'),
          },
        })
        this.uploads = await response.json();
      } catch (error) {
        console.log(error);
      }
    },
  },
  created() {
    this.getAllUploads();
  },
}
</script>
