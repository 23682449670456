<template>
    <router-link to="" class="flex flex-col bg-white rounded-xl mb-5 p-4 md:w-11/12 hover:shadow-2xl">
      <div class="flex">
        <div>
          <div class="flex rounded-full h-20 w-20 items-center justify-center text-olive font-bold text-xl border-2 border-olive">{{ $filters.initials(this.profile.full_name) }}</div>
        </div>
        <div class="flex flex-col ml-3 flex-wrap">
          <div class="text-olive font-bold text-xl">{{ this.profile.full_name }}</div>
          <div class="">{{ this.profile.current_role }} at {{ this.profile.current_company }}</div>
          <div>{{ this.profile.location }}</div>
        </div>
      </div>
      <hr class="my-3 color-olive" />
      <div class="flex content-center justify-content-center justify-items-center text-center">
        <div class="flex-1 font-bold text-olivelight">Total exp.</div>
        <div class="flex-1 font-bold text-olivelight">Company exp.</div>
        <div class="flex-1 font-bold text-olivelight">Company tenure</div>
      </div>
      <div class="flex content-center justify-content-center justify-items-center text-center">
        <div class="flex-1">{{ $filters.screenedDate(this.profile.total_experience) }}</div>
        <div class="flex-1">{{ $filters.screenedDate(this.profile.experience_company) }} </div>
        <div class="flex-1">{{ $filters.screenedDate(this.profile.loyalty) }}</div>
      </div>
    </router-link>
</template>

<script>
export default {
  name: 'BigCard',
  props: ['profile'],
}
</script>