<template>
    <div class="rounded-lg shadow-xl bg-gray-50 my-4">
        <div class="m-4 pt-3">
            <div class="flex items-center justify-between">
                <div class="text-olive pt-5 pb-2 text-xl font-bold">Upload profile</div>
            </div>

            <label class="inline-block mb-2 text-gray-500">@Denny to write a copy here with details on selecting a resume then submitting the upload request. The uploads are processed separately and the status of the upload can be seen on the Upload History (icon above right) </label>

            <div v-if="success" class="bg-green-100 border border-green-400 text-green-700 px-4 py-3 rounded my-3" role="alert">
                <strong class="font-bold">Success! </strong>
                <span class="block sm:inline">The resume you have submitted as been queued for processing. You can view the status of your request by visiting the <b>Upload History</b> page.</span>
                <span class="absolute top-0 bottom-0 right-0 px-4 py-3">
                <svg class="fill-current h-6 w-6 text-red-500" role="button" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><title>Close</title><path d="M14.348 14.849a1.2 1.2 0 0 1-1.697 0L10 11.819l-2.651 3.029a1.2 1.2 0 1 1-1.697-1.697l2.758-3.15-2.759-3.152a1.2 1.2 0 1 1 1.697-1.697L10 8.183l2.651-3.031a1.2 1.2 0 1 1 1.697 1.697l-2.758 3.152 2.758 3.15a1.2 1.2 0 0 1 0 1.698z"/></svg>
                </span>
            </div>

            <div v-if="error" class="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded my-3" role="alert">
                <strong class="font-bold">Error! </strong>
                <span class="block sm:inline">{{ this.errorMessage }}</span>
                <span class="absolute top-0 bottom-0 right-0 px-4 py-3">
                <svg class="fill-current h-6 w-6 text-red-500" role="button" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><title>Close</title><path d="M14.348 14.849a1.2 1.2 0 0 1-1.697 0L10 11.819l-2.651 3.029a1.2 1.2 0 1 1-1.697-1.697l2.758-3.15-2.759-3.152a1.2 1.2 0 1 1 1.697-1.697L10 8.183l2.651-3.031a1.2 1.2 0 1 1 1.697 1.697l-2.758 3.152 2.758 3.15a1.2 1.2 0 0 1 0 1.698z"/></svg>
                </span>
            </div>

            <div v-if="isFileUploaded && !error" class="flex flex-col items-center justify-center w-full">
                <div>You are about to submit the following file for processing:</div>
                <div class="flex">
                    <div class="text-olive">
                        <svg xmlns="http://www.w3.org/2000/svg" class="h-9 w-9" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 12h6m-6 4h6m2 5H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z" />
                        </svg>
                    </div>

                    <div class="text-xl">
                        {{ this.uploadedFile.name }}
                    </div>
                </div>
            </div>

            <div v-if="!isFileUploaded" class="flex items-center justify-center w-full">
                <label class="flex flex-col w-full h-32 border-4 border-olive border-dashed hover:bg-gray-100 hover:border-gray-300">
                    <div class="flex flex-col items-center justify-center pt-7">
                        <svg xmlns="http://www.w3.org/2000/svg" class="w-8 h-8 text-gray-400 group-hover:text-gray-600" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12"></path>
                        </svg>
                        <p class="pt-1 text-sm tracking-wider text-gray-400 group-hover:text-gray-600">
                            Click to select a resume file
                        </p>
                    </div>

                    <input type="file" class="opacity-0" @change="fileUpload">
                </label>
            </div>
        </div>
        
        <div class="flex justify-around p-4">
            <button  v-if="isFileUploaded" class="w-80 px-4 py-2 text-white bg-red-600 rounded shadow-xl" @click="cancelUpload">Cancel</button>
            <button  v-if="isFileUploaded" class="w-80 px-4 py-2 text-white bg-olive rounded shadow-xl" @click="processResumeUpload">Upload</button>
        </div>
    </div>
</template>

<script>
let apiEndpoint = process.env.VUE_APP_API_BASEURL

export default {
    name: 'ResumeUploadComponent',
    components: {
    },
    data() {
    return {
      uploadedFile: null,
      isFileUploaded: false,
      success: false,
      error: false,
      errorMessage: '',
    };
  },
    methods: {
        fileUpload(event) {
            this.success = false
            this.error = false
            this.errorMessage = ''


            const allowedTypes = ["application/pdf", "application/msword", "application/vnd.openxmlformats-officedocument.wordprocessingml.document"];
            let currentFile = event.target.files[0]
            if (allowedTypes.includes(currentFile.type)) {
                this.uploadedFile = event.target.files[0]
                this.isFileUploaded = true
            } else {
                this.error = true
                this.errorMessage = 'Invalid file selected. Only PDF, DOC and DOCX files are allowed.'
            }
        },
        cancelUpload() {
            this.uploadedFile = null
            this.isFileUploaded = false
            this.error = false
            this.errorMessage = ''
        },
        async processResumeUpload() {
            if (this.isFileUploaded) {
                var data = new FormData()
                data.append('file', this.uploadedFile)

                fetch(apiEndpoint + "/profile.php?uploadResume", {
                    method: 'POST',
                    body: data,
                    headers: {
                        'Authorization': 'Bearer ' +  localStorage.getItem('screenedToken'),
                    },
                })
                .then(response => response.json())
                .then(data => {
                    if (data.error) {
                        this.uploadedFile = null
                        this.isFileUploaded = false
                        this.error = true
                        this.errorMessage = data.errorMessage

                    } else if (data.success) {
                        this.uploadedFile = null
                        this.isFileUploaded = false
                        this.error = false
                        this.errorMessage = ''
                        this.success = true
                    }
                })
                // fetch(apiEndpoint + "/profile.php?uploadResume", {
                //     method: 'POST',
                //     body: data
                // })
            }
        }
    }
}
</script>