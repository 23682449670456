<template class="h-screen bg-gray-100">
    <div class="pt-7 flex justify-center">
      <div class="bg-white shadow-md rounded-xl px-8 pt-6 pb-8 mb-4 flex flex-col w-1/2">
        <div v-if="this.error" class="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative mb-4" role="alert">
          <span class="block sm:inline">You have entered an invalid username or password</span>
        </div>
        <div class="mb-4">
          <label class="block text-grey-darker text-sm font-bold mb-2" for="username">
            Company Email address
          </label>
          <input class="shadow appearance-none border rounded w-full py-2 px-3 text-grey-darker" id="email" v-model="email" type="text">
        </div>
        <div class="mb-6">
          <label class="block text-grey-darker text-sm font-bold mb-2" for="password">
            Company Password
          </label>
          <input class="shadow appearance-none border border-red rounded w-full py-2 px-3 text-grey-darker mb-3" id="password" v-model="password" type="password">
        </div>
        <div class="flex items-end justify-between">
          <router-link to="/marketplace/company/signup">
            <button class="bg-olive hover:bg-blue-dark text-white font-bold py-2 px-4 rounded" type="button">
              Sign Up
            </button>
          </router-link>

          <button class="bg-olive hover:bg-blue-dark text-white font-bold py-2 px-4 rounded" type="button" @click="submitLogin">
            {{ SignInButtonText }}
          </button>
        </div>
    </div>
  </div>
</template>

<script>
let apiEndpoint = process.env.VUE_APP_API_BASEURL

export default {
  name: 'Login',
  components: {
  },
  data() {
    return {
      email:"",
      password: "",
      error: false,
      SignInButtonText: "Sign In"
    };
  },
  methods: {
    submitLogin() {
      this.error = false
      if (this.email != "" && this.password != "") {
        // Build submission form
        let formData = new FormData();
        formData.append('companyEmail', this.email);
        formData.append('companyPass', this.password);

        fetch(apiEndpoint + "/auth.php", {
          body: formData,
          method: "POST"
        })
        .then(response => response.json())
        .then(data => {
          if (data.success) {
            // Set state
            this.$store.commit('setLoggedIn', true)

            // Write token to localStorage
            localStorage.setItem('companyToken', data.token);

            // Redirect to profiles
            this.$router.push('profiles') 
          } else {
            // Clear values
            this.email = ""
            this.password = ""

            // Set state
            this.$store.commit('setLoggedIn', false)
            // Remove token from local storage
            localStorage.removeItem('companyToken');

            // Set error to true
            this.error = true
          }
        })
      } else {
        this.error = true
      }
    },
    logoutUser() {  
        // Set state
        this.$store.commit('setLoggedIn', false)
        // Remove localstorage
        localStorage.removeItem('companyToken');

        // Redirect to login
        this.$router.push('Login') 
    },
  },
  created() {
    // this.logoutUser();
  },
}
</script>
