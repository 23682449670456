<template>
    <div class="mt-7 flex-col">
      <hr />
      <div class="flex justify-center mt-3 font-bold text-xl text-olive select-none">Stats</div>
      <div class="mt-4 flex text-center">
        <div class="flex-1 font-normal hover:text-lg select-none">
          <div class="font-bold">Avg. Salary</div>
          <div class="">{{ $filters.statsAmount(this.stats.avgSalary) }}</div>
        </div>
        <div class="flex-1 font-normal hover:text-lg select-none">
          <div class="font-bold">Avg. Rate</div>
          <div class="">{{ $filters.statsAmount(this.stats.avgRate) }}</div>
        </div>
        <div class="flex-1 font-normal hover:text-lg select-none">
          <div class="font-bold">Avg. Loyalty</div>
          <div class="hover:text-olive">{{ $filters.statsPeriod(this.stats.avgLoyalty) }}</div>
        </div>
        <div class="flex-1 font-normal hover:text-lg select-none">
          <div class="font-bold">Avg. Experience</div>
          <div class="hover:text-olive">{{ $filters.statsPeriod(this.stats.avgExperience) }}</div>
        </div>
        <div class="flex-1 font-normal hover:text-lg select-none">
          <div class="font-bold">Avg. Tenure</div>
          <div class="hover:text-olive">{{ $filters.statsPeriod(this.stats.avgCompany) }}</div>
        </div>
      </div>
    </div>
</template>

<script>
let apiEndpoint = process.env.VUE_APP_API_BASEURL

export default {
  name: 'TalentPoolStats',
  data() {
    return {
      stats: {
        avgSalary: 'c',
        avgRate: 'c',
        avgExperience: 'c',
        avgLoyalty: 'c',
        avgCompany: 'c'
      }
    };
  },
  methods: {
    async getPoolStats() {
        let response = await fetch(apiEndpoint + "/talentpool.php?getStats&tph=" + this.$route.params.poolHash, {
          headers: {
            'Authorization': 'Bearer ' +  localStorage.getItem('screenedToken'),
          },
        })
        this.stats = await response.json();
    },
  },
  created() {
    this.getPoolStats();
  },
}
</script>