<template>
    <div class="flex w-100 bg-gray-200  mb-5">
        <div class="flex flex-col w-5/6 p-5">

            <div class="flex flex-col w-5/6 p-5">
                <div class="flex flex-col flex-grow mb-2">
                    <div class="text-olive font-bold text-xl">{{ this.profile.first_name }}  {{ this.profile.last_name }}</div>
                    <div v-if="this.profile.current_role != null">{{ this.profile.current_role }}</div>
                    <div v-if="this.profile.location != null">{{ this.profile.location }}</div>
                </div>
                <hr>
                <div class="flex mt-3">
                    <div class="flex flex-basis-30 font-bold">Company</div>
                    <div class="flex flex-basis-70">{{ this.profile.current_company ? this.profile.current_company : 'Not employed' }}</div>
                </div>
                <div class="flex">
                    <div class="flex flex-basis-30 font-bold">Current tenure</div>
                    <div class="flex flex-basis-70">{{ $filters.screenedDate(this.profile.updated_experience_company) }}</div>
                </div>
                <div class="flex">
                    <div class="flex flex-basis-30 font-bold">Total experience</div>
                    <div class="flex flex-basis-70">{{ $filters.screenedDate(this.profile.updated_total_experience) }}</div>
                </div>
                <div class="flex">
                    <div class="flex flex-basis-30 font-bold">Companies</div>
                    <div class="flex flex-basis-70">{{ this.profile.total_companies }} <span class="text-base flex items-center" v-if="this.profile.companies != null">&nbsp;({{ JSON.parse(this.profile.companies).join(', ') }})</span></div>
                </div>
                <div class="flex">
                    <div class="flex flex-basis-30 font-bold">Company tenure</div>
                    <div class="flex flex-basis-70">{{ $filters.screenedDate(this.profile.loyalty) }}</div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
let apiEndpoint = process.env.VUE_APP_API_BASEURL

export default {
    name: 'BigCardContractor',
    components: {},
    data() {
        return {
        }
    },
    props: ['profile', 'talentPool'],
    methods: {
    }
}
</script>