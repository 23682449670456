<template>
  <div class="pt-7">
    <div class="bg-white rounded-xl p-5 flex flex-col">
      <h1 class="text-3xl text-olive font-bold">{{ profile.full_name }}</h1>
      <div class="text-xl">{{ profile.location }}</div>
    </div>
  </div>
</template>

<script>
let apiEndpoint = process.env.VUE_APP_API_BASEURL

export default {
  name: 'TalentPool',
  data() {
    return {
      profile: [],
    };
  },
  methods: {
    async getIndividualProfile() {
      try {
        // let response = await fetch(apiEndpoint + "/profile.php?getIndividual&ph=" + this.$route.params.profileHash);
        let response = await fetch(apiEndpoint + "/profile.php?getIndividual&ph=" + this.$route.params.profileHash, {
          headers: {
            'Authorization': 'Bearer ' +  localStorage.getItem('screenedToken'),
          },
        })
        this.profile = await response.json();
        this.$emit('setChildBreadcrumb', this.profile.full_name);
      } catch (error) {
        console.log(error);
      }
    },
  },

  created() {
    this.getIndividualProfile();
  },
}
</script>
